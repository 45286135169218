
import {
    defineComponent,
    onMounted,
    ref,
    reactive,
    nextTick,
    h,
    computed,
    watch,
} from 'vue';
import { useStore } from 'vuex';
import BuSelector from '@/views/LocalRegistration/components/BuSelector.vue';
import {
    getCityListByProvinceId,
    getAllProvince,
    getLicenseMappingCityList,
    exportLicenseMappingCityList,
    getAllCitys,
    getCityInfo,
    createLicenseMappingCity,
    editLicenseMappingCity,
    getLicenseMappingCityDetail,
} from '@/API/localRegistration';
import { downloadFromStream } from '@/utils/payment/downloadFile';
import { elementSize } from '@/utils';
export default defineComponent({
    components: {
        BuSelector,
    },
    setup() {
        const store = useStore();
        const buList = store.state.user.buTreeList.filter((item: { nameEn: string }) =>
                ['MBPC', 'AMG', 'VAN', 'G', 'Maybach'].includes(item.nameEn)
            );
        // console.log(buList)
        const stringArrayBuList = buList.map((item: { nameEn: string }) => item.nameEn);

        const searchCondition = reactive({
            bu: '',
            licensePlate: '',
            provinceFullnameCn: '',
            cityFullnameCn: '',
        });
        searchCondition.bu = stringArrayBuList[0];

        const createInfo = reactive({
            licensePlate: '',
            cityFullnameCn: '',
            cityNameCn: '',
            city: '',
            provinceFullnameCn: '',
            provinceShortNameCn: '',
            provinceNameCn: '',
            province: '',
            administrativeDivision: '',
            mark: '',
        });

        const editInfo = reactive({
            id: '',
            licensePlate: '',
            cityFullnameCn: '',
            cityNameCn: '',
            city: '',
            provinceFullnameCn: '',
            provinceShortNameCn: '',
            provinceNameCn: '',
            province: '',
            administrativeDivision: '',
            mark: '',
        });

        const pagination = reactive({
            current: 1,
            pageSize: 10,
            total: 0,
        });

        const tableSize = elementSize('.table');

        const provinceOptions = ref<any[]>([]);
        const cityOptions = ref<any[]>([]);
        const allCityOptions = ref<any[]>([]);

        const showAddDrawer = ref<boolean>(false);
        const showEditDrawer = ref<boolean>(false);
        const isEdit = ref<boolean>(false);
        const columns = [
            {
                title: 'License plate',
                dataIndex: 'licensePlate',
                key: 'licensePlate',
            },
            {
                title: 'Province',
                dataIndex: 'province',
                key: 'province',
            },
            {
                title: '省份全称',
                dataIndex: 'provinceFullnameCn',
                key: 'provinceFullnameCn',
            },
            {
                title: '省份简称',
                dataIndex: 'provinceNameCn',
                key: 'provinceNameCn',
            },
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
            },
            {
                title: '地级市全称',
                dataIndex: 'cityFullnameCn',
                key: 'cityFullnameCn',
            },
            {
                title: '地级市简称',
                dataIndex: 'cityNameCn',
                key: 'cityNameCn',
            },
            {
                title: 'Operation',
                dataIndex: 'operation',
                key: 'operation',
                slots: { customRender: 'operation' },
            },
        ];
        const tableData = ref<any[]>([]);

        const handleProvinceChange = (value: any) => {
            const province = provinceOptions.value.filter(
                (item) => item.name === value
            )[0];
            const id = province.id;
            const params = {
                provinceId: id,
            };
            searchCondition.cityFullnameCn = '';
            getCityListByProvinceId(params).then((res: any) => {
                cityOptions.value = res.data;
            });
        };
        const filterOption = (input: any, option: any) => {
            return option.children[0].children.indexOf(input) >= 0;
        };

        const handleReset = () => {
            searchCondition.bu = buList[0];
            searchCondition.licensePlate = '';
            searchCondition.provinceFullnameCn = '';
            searchCondition.cityFullnameCn = '';
        };

        const handleSearch = () => {
            pagination.current = 1;
            const params = {
                current: pagination.current,
                size: pagination.pageSize,
            };
            const data = {
                ...searchCondition,
            };
            getLicenseMappingCityList(data, params).then((res: any) => {
                tableData.value = res.content;
                pagination.total = res.totalElements;
            });
        };

        const handlePageChange = () => {
            const params = {
                current: pagination.current,
                size: pagination.pageSize,
            };
            const data = {
                ...searchCondition,
            };
            getLicenseMappingCityList(data, params).then((res: any) => {
                tableData.value = res.content;
            });
        };

        const handleExport = () => {
            const data = {
                ...searchCondition,
            };
            exportLicenseMappingCityList(data).then((res) => {
                downloadFromStream(res);
            });
        };
        const handleView = (record: any) => {
            getLicenseMappingCityDetail({ id: record.id }).then((res: any) => {
                console.log(res);
                editInfo.city = res.data.city;
                editInfo.licensePlate = res.data.licensePlate;
                editInfo.cityFullnameCn = res.data.cityFullnameCn;
                editInfo.cityNameCn = res.data.cityNameCn;
                editInfo.city = res.data.city;
                editInfo.provinceFullnameCn = res.data.provinceFullnameCn;
                editInfo.provinceShortNameCn = res.data.provinceShortNameCn;
                editInfo.provinceNameCn = res.data.provinceNameCn;
                editInfo.province = res.data.province;
                editInfo.administrativeDivision =
                    res.data.administrativeDivision;
                editInfo.mark = res.data.mark;
                editInfo.id = res.data.id;
                showEditDrawer.value = true;
            });
        };

        const handleAddChangeCity = (value: any, option: any) => {
            getCityInfo({ id: option.key }).then((res: any) => {
                createInfo.cityNameCn = res.data.shortName;
                createInfo.city = res.data.nameEn;
                createInfo.provinceFullnameCn = res.data.parentName;
                createInfo.provinceShortNameCn =
                    res.data.internalShortName || '';
                createInfo.provinceNameCn = res.data.parentShortName;
                createInfo.province = res.data.parentNameEn;
            });
        };
        const handleEditChangeCity = (value: any, option: any) => {
            getCityInfo({ id: option.key }).then((res: any) => {
                editInfo.cityNameCn = res.data.shortName;
                editInfo.city = res.data.nameEn;
                editInfo.provinceFullnameCn = res.data.parentName;
                editInfo.provinceShortNameCn = res.data.internalShortName || '';
                editInfo.provinceNameCn = res.data.parentShortName;
                editInfo.province = res.data.parentNameEn;
            });
        };
        const handleAddSave = () => {
            const data = {
                bu: searchCondition.bu,
                ...createInfo,
            };
            createLicenseMappingCity(data).then((res: any) => {
                handleSearch();
                showAddDrawer.value = false;
            });
        };
        const handleEditSave = () => {
            const data = {
                bu: searchCondition.bu,
                ...editInfo,
            };
            editLicenseMappingCity(data).then((res: any) => {
                handleSearch();
                showEditDrawer.value = false;
            });
        };

        const init = () => {
            getAllProvince().then((res: any) => {
                provinceOptions.value = res;
            });
            getAllCitys().then((res: any) => {
                allCityOptions.value = res;
            });
        };
        init();

        return {
            buList,
            filterOption,
            searchCondition,
            createInfo,
            editInfo,
            provinceOptions,
            cityOptions,
            allCityOptions,
            pagination,
            tableSize,
            columns,
            tableData,
            isEdit,
            showAddDrawer,
            showEditDrawer,
            handleView,
            handleReset,
            handleSearch,
            handlePageChange,
            handleExport,
            handleProvinceChange,
            handleAddChangeCity,
            handleEditChangeCity,
            handleAddSave,
            handleEditSave,
        };
    },
});
